<template>

    <div id="main">
        
        <div id="banner" class="kefan">
            <div class="block">
                <el-carousel trigger="click" height="380px">
                <el-carousel-item v-for="item in banner" :key="item.id">
                    <el-image style="width:1103px;height:400px" :src="item.src" :fit="'cover'" ></el-image>
                </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <div id="bottom" class="kefan" >
            <div class="bottom" @click="tomall">
                 <div class="bottom-top">
                    <i class="el-icon-thumb"></i>
                </div>
                 <div class="bottom-down"  >
                    <span>添加商业数据</span>
                </div>
            </div>
        </div>
    </div>
</template> 
<script>
export default {
    data() {
        return {
            // isfresh:false
            banner:[
                {id:1,src:this.$host+"mallcategory/banner/1.jpg"},
                {id:2,src:this.$host+"mallcategory/banner/2.jpg"},
                {id:3,src:this.$host+"mallcategory/banner/3.jpg"},
                {id:4,src:this.$host+"mallcategory/banner/4.jpg"}, 
                {id:5,src:this.$host+"mallcategory/banner/5.jpg"}, 
                {id:6,src:this.$host+"mallcategory/banner/6.jpg"}, 
                {id:7,src:this.$host+"mallcategory/banner/7.jpg"}, 
                {id:8,src:this.$host+"mallcategory/banner/8.jpg"}, 
            ],
                    //     gridData: [{
        //   date: '2016-05-02',
        //   name: '王小虎',
        //   address: '上海市普陀区金沙江路 1518 弄'
        // }, {
        //   date: '2016-05-04',
        //   name: '王小虎',
        //   address: '上海市普陀区金沙江路 1518 弄'
        // }, {
        //   date: '2016-05-01',
        //   name: '王小虎',
        //   address: '上海市普陀区金沙江路 1518 弄'
        // }, {
        //   date: '2016-05-03',
        //   name: '王小虎',
        //   address: '上海市普陀区金沙江路 1518 弄'
        // }],
        
        }
    },
    methods: {
        tomall(){
            this.$router.push({path:"/mall"})
        }
    },
    mounted() {
    },   
}
</script>
<style scoped>
    #main{
        margin-left: 20px;
        margin-right: 40px;
        margin-top:20px;
    }
    #main #banner{
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .block{
        width: 80%;
        overflow: hidden;
    }
    #bottom{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bottom{
        padding: 30px;
        border-radius: 30%;
        text-align: center;
        /* border: 2px solid #4fc08d; */
        cursor: pointer;
        background-color: #ffe84f;
        color: #fff;
        transition: all 0.7s ease ;
    }
    .bottom:hover{
        background-color: #ffa200;
        /* border: 2px solid #328c0b; */
        color: #fff;
        box-shadow: 0 2px 15px #aeaeae;
        transition: all 0.7s ease ;
    }
    .bottom-top{
        font-size: 40px;
        
    }
    .bottom-down{
        font-size: 20px;
        
    }
    
</style>